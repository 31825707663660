<template>
  <div class="pools">
    <Headbar bgColor="transparent" :isBack="false" title="投入">
    </Headbar>

    <div class="title">DG简介</div>
    <div class="label">
      <div class="article" v-html="article"></div>
    </div>

    <div class="total">
      <div class="total-label">锁仓金额</div>
      <div class="total-jine">{{ showToFixed(touzi.suocang) }}</div>
      <div class="total-label">已释放金额</div>
      <div class="total-lirun">{{ showToFixed(touzi.shifang) }}</div>
    </div>

    <div class="pool-bill">
      <div>我的投入：<span style="color: #02AB77;">{{ showToFixed(touzi.touru) }}</span></div>
      <!-- <div>我的收益：<span style="color: #02AB77;">0.00</span></div> -->
    </div>

    <!-- <div class="form">
      <div class="select" @click="show = true">
        <div>支付方式</div>
        <div class="row items-center">
          <div class="select-val" v-if="wallet2">组合支付</div>
          <div class="select-val" v-else>{{ wallet.cnameZh }}</div>
          <q-icon name="keyboard_arrow_down" class="icon"></q-icon>
        </div>
      </div>
      <div>
        <div class="input-wrap mt-md">
          <div class="input-label" style="margin-right: 30rpx;">投入资金</div>
          <div style="flex: 1;">
            <q-input borderless dense :input-style="{ color: '#fff' }" placeholder="请输入投资金额" v-model="jine" />
          </div>
          <div class="q-ml-sm row item-center">
            <span>{{ wallet.cnameZh }}</span>
            <span class="q-px-xs">|</span>
            <span style="color: #E7BA47;" @click="jine = wallet.jine">全部</span>
          </div>
        </div>
        <div class="balance">可用余额：<span style="color: #FFD585;">{{ wallet.jine }} {{ wallet.cnameZh }}</span></div>
      </div>
      <div v-if="wallet2">
        <div class="input-wrap mt-md">
          <div class="input-label" style="margin-right: 30rpx;">投入资金</div>
          <div style="flex: 1;">
            <q-input borderless dense :input-style="{ color: '#fff' }" placeholder="请输入投资金额" v-model="jine2" />
          </div>
          <div class="q-ml-sm row item-center">
            <span>{{ wallet2.cnameZh }}</span>
            <span class="q-px-xs">|</span>
            <span style="color: #E7BA47;" @click="jine2 = wallet2.jine">全部</span>
          </div>
        </div>
        <div class="balance">可用余额：<span style="color: #FFD585;">{{ wallet2.jine }} {{ wallet2.cnameZh }}</span></div>
      </div>
    </div>

    <div style="margin-top: 25px;">
      <div class="m-btn" @click="submit">立即投入</div>
    </div>

    <van-popup v-model:show="show" position="bottom">
      <van-picker :confirm-button-text="$t('确定')" value-key="cnameZh" :cancel-button-text="$t('取消')" :columns="selectList"
        @confirm="onConfirm" @cancel="show = false" />
    </van-popup> -->

    <div class="invest">
      <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" :finished-text="$t('没有更多了')"
        :immediate-check="false" offset="10" @load="onLoad">
        <div class="invest-item" v-for="item in list" :key="item.id">
          <div class="item-top">
            <div>{{ $t('投资金额') }}</div>
            <div style="color: #FFD585;">
              <!-- {{ item.stateName }} -->
              {{ item.amount.toFixed(2) }}
            </div>
          </div>
          <div class="cell" style="color: #999;">
            <div>{{ item.createTime }}</div>
          </div>
        </div>
      </van-list>

      <empty v-if="list.length == 0"></empty>
    </div>

    <CheckRegister @refresh="refresh"></CheckRegister>

    <TabBar active="2"></TabBar>
  </div>
</template>

<script>
import TabBar from '@/components/TabBar'
import { ref } from 'vue';
import CustomComponent from "@/components/CustomComponent";
import { Picker, Popup, List } from 'vant';
import Web3Helper from "../../assets/js/web3helper.js";
import CheckRegister from '@/components/CheckRegister'

export default {
  name: '',
  components: {
    TabBar,
    CheckRegister,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [List.name]: List,
  },
  setup() {
    return {
      us: ref(null),
      article: ref(""),
      show: ref(false),
      selectList: ref({}),
      wallet: ref({}),
      wallet2: ref(null),
      jine: ref(""),
      jine2: ref(""),

      touzi: ref({}),

      list: ref([]),
      pageIndex: ref(1),
      loading: ref(false),
      finished: ref(false),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.getWallet()
      this.getTouziInfo()
      this.getlist()
    }
    this.getArticle()
  },
  methods: {
    refresh() {
      this.us = JSON.parse(this.$utils.getloc("us"))
      if (this.us) {
        this.getWallet()
        this.getlist()
      }
    },
    tolink(path) {
      this.$router.push(path)
    },
    onConfirm(e) {
      if (e.cid == 99) {
        this.wallet = this.selectList[0]
        this.wallet2 = this.selectList[1]
      } else {
        this.wallet = e
        this.wallet2 = null
      }
      this.show = false
    },
    showToFixed(data) {
      if (data) {
        return data.toFixed(2)
      } else {
        return "0.00"
      }
    },
    getlist() {
      let _this = this
      _this.$request.post(
        "api/TouziOrder/List",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          pageIndex: _this.pageIndex,
          pageSize: 10
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          if (data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          _this.list = data
        }
      )
    },
    getArticle() {
      let _this = this
      _this.$request.post(
        "api/Article/Get",
        {
          select_id: 10
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            })
            return
          }
          let data = res.data.data
          _this.article = data.articlecontent
        }
      )
    },
    getTouziInfo() {
      let _this = this
      _this.$request.post(
        "api/TouziOrder/GetTouziInfo",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            })
            return
          }
          let data = res.data.data
          _this.touzi = data
        }
      )
    },
    getWallet() {
      let _this = this
      _this.$request.post(
        "api/Wallets/GetWallet",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            })
            return
          }
          let data = res.data.data
          data = data.filter(item => item.cid == 2 || item.cid == 3)
          data.push({
            cid: 99,
            cnameZh: "组合支付"
          })
          _this.selectList = data
          if (data.length > 0 && Object.keys(_this.wallet).length == 0) {
            _this.wallet = data[0]
          } else {
            _this.wallet = data.find(item => item.cid == _this.wallet.cid)
          }
          if (_this.wallet2) {
            _this.wallet2 = data.find(item => item.cid == _this.wallet2.cid)
          }
          // console.log(data)
        }
      )
    },
    submit() {
      let _this = this
      if (Object.keys(this.wallet).length == 0) {
        this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请选择支付方式"],
            persistent: true,
          }
        });
        return
      }
      if (this.jine == "") {
        this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入投资金额"],
            persistent: true,
          }
        });
        return
      }
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ["确定要投资吗?"],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        this.$q.loading.show({
          message: _this.$i18n.t("投入中...")
        })
        let payListJson = [{
          cid: _this.wallet.cid,
          jine: _this.jine
        }]
        if (_this.wallet2) {
          payListJson.push({
            cid: _this.wallet2.cid,
            jine: _this.jine2
          })
        }
        let web3Helper = new Web3Helper();
        web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/TouziOrder/Touzi",
            {
              token: _this.$utils.getloc("token"),
              userid: _this.us.userid,
              address: _this.us.userid,
              signStr: signStr,
              payListJson: JSON.stringify(payListJson)
            },
            (res) => {
              _this.$q.loading.hide();
              _this.$q.dialog({
                component: CustomComponent,
                componentProps: {
                  messages: [res.data.msg],
                  persistent: true,
                }
              })
              if (res.data.code == 100) {
                _this.getWallet()
              }
            }
          )
        })
      })
    }
  }
}
</script>

<style scoped>
.pools {
  padding: 10px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #FFD585;
}

.label {
  color: #B5B8BB;
  font-size: 13px;
}

.label>div {
  margin-top: 6px;
}

.total {
  margin-top: 15px;
  padding: 10px;
  background: url(~@/assets/img/base/fund-total.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 6px;
}

.total-label {
  color: #B5B8BB;
}

.total-jine {
  padding: 8px 0;
  font-size: 25px;
  font-weight: bold;
  color: #FFD585;
}

.total-lirun {
  margin-top: 4px;
  font-size: 18px;
  color: #02AB77;
}

.pool-bill {
  margin-top: 10px;
  color: #fff;
  display: flex;
  justify-content: space-around;
}

.form {
  margin-top: 15px;
}

.select {
  padding: 11px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  font-size: 15px;
  color: #fff;
  background-color: #1B1B1B;
}

.select-val {
  margin-right: 5px;
}

.icon {
  font-size: 18px;
  color: #fff;
}

.input-wrap {
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  color: #fff;
  background-color: #1B1B1B;
}

.input-label {
  margin-right: 4px;
  width: 75px;
  color: #fff;
  font-size: 14px;
}

.mt-md {
  margin-top: 15px;
}

:deep(.q-field--dense .q-field__control) {
  height: 24px;
}

.balance {
  margin-top: 6px;
  color: #B5B8BB;
  font-size: 12px;
}

.invest {
  margin-top: 15px;
}

.invest-item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  background-color: #1B1B1B;
}

.cell {
  padding: 2px 0;
  display: flex;
  justify-content: space-between;
}

.item-top {
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(48, 45, 45)
}
</style>